.PhoneInput {
    /* border: 2px solid black; */
    line-height: 10px;
}
.PhoneInputCountrySelectArrow {
    color: black;
}

.PhoneInputInput {
    ::placeholder {
        color: var(--Solid-Colours-Brand-Gray-Gray-500, #cacdd5);
    }

    border-bottom: 1px solid #9b9b9b;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-bottom: 10px;
    padding-top: 2px;
    &:focus {
        outline: none;
        background-color: white;
    }
}
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    flex-direction: column;
}
.page-info {
    font-size: 1rem;
    color: #5fb3f6;
}
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
}
.pagination li {
    margin: 0 0.5rem;
}
.pagination li a {
    color: #5fb3f6;
    text-decoration: none;
    cursor: pointer;
}
.pagination li.active a {
    font-weight: bold;
    color: #0056b3;
}
.pagination li.disabled a {
    color: #ccc;
    cursor: not-allowed;
}
.btn-secondary {
    background-color: #ffffff;
    border: none;
    color: black;
    width: 80%;
}
.btn-secondary:hover {
    background-color: #ffffff;
    border: none;
    color: black;
    width: 80%;
}
.btn-secondary:active {
    background-color: #ffffff;
    border: none;
    color: black;
    width: 80%;
}
.background-shade {
    width: 100%;
    background: radial-gradient(
        circle,
        rgba(100, 200, 255, 0.5),
        rgba(255, 100, 200, 0.5)
    );
    padding: 20% 0% 0% 0%;
    position: relative;
}
/* .rounded-circle {
    border: 3px solid white;
    position: absolute;
    top: 15%;
    width: 15%;
    left: 5%;
} */
.badge {
    background-color: green;
    color: white;
    padding: 11px 30px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    position: absolute;
    bottom: -15%;
    right: 0%;
}
.profileContent {
    padding: 5%;
    margin-top: 5%;
}
/* EventManagement.css */
.sc-navEnt-link {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    background: #efeefe;
    color: #464646;
    padding: 0.4rem;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sc-navEnt-link:hover {
    background: #dcdcdc;
    color: #333333;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sc-navEnt-link.active {
    background: #3a83f7;
    color: #ffffff;
    padding: 0.4rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.sc-navAnsmnt-link {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    background: #efeefe;
    color: #464646;
    padding: 0.4rem;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.sc-navAnsmnt-link:hover {
    background: #dcdcdc;
    color: #333333;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sc-navAnsmnt-link.active {
    background: #3a83f7;
    color: #ffffff;
    padding: 0.4rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.custom-select-container {
    position: relative;
}

.custom-select-arrow {
    position: relative;
    background: url('./asset/icons/DropDown.svg') no-repeat right center;
    background-size: 16px 16px;
}

.custom-select-arrow option[data-icon='dropdown'] {
    background: url('./asset/icons/DropDown.svg') no-repeat left center;
    padding-left: 15px;
}

.custom-select-arrow option {
    padding-left: 15px;
}
