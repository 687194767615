.scrollable-container {
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-radius: 12px;
}

.scrollable-container::-webkit-scrollbar {
    width: 12px;
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #6c757d;
    border-radius: 6px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #f1f1f1;
}

/* For Firefox */
.scrollable-container {
    scrollbar-width: thin;
    scrollbar-color: #6c757d #f1f1f1;
}
