.custom-button {
    transition: all 0.3s ease;
    cursor: pointer;
}

.custom-button:hover {
    background-color: #107BFF !important; /* Change to a darker blue on hover */
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.6) !important; /* Increase shadow on hover */
}

.custom-button:disabled {
    /* background: grey !important;
    border-color: grey !important; */
    cursor: not-allowed; 
    /* box-shadow: none !important;  */
    /* color: #b3b3b3 !important;  */
}