.cc-modal-body {
    min-width: 921px;
}

.cc-modal-content {
    border-radius: 0px !important;
}

.modal-body {
    overflow-x: hidden;
    overflow-y: auto;
}