.nav-pills .nav-link {
    color: #464646;
    background-color: #efeefe;
    font-size: '12px';
    font-weight: '600';
}

.nav-pills .nav-link.active {
    color: #fff;
    background-color: #467aff;
    font-size: '12px';
    font-weight: '600';
}
/* .dropdownFilter {
    background-color: #3b82f6;
    border: none;
    color: white;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
}  */

.table {
    margin: auto;
    width: 100%;
    border-radius: 22px;
}

.table th,
.table td {
    border: 1px solid rgb(239, 238, 254);
    padding: 8px;
}

.table th {
    background-color: #f2f2f2;
    text-align: center;
    color: #467aff;
    font-size: 14px;
    font-weight: 600;
}

button {
    margin: 0 10px;
}

form {
    margin: 20px 0;
    text-align: left;
}

.recharts-legend-wrapper {
    bottom: 0px;
    height: inherit;
    padding-bottom: 3%;
    top: 0%;
}
.recharts-wrapper {
    width: 50%;
    height: 50%;
}
