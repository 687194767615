.left-div {
    margin: 10px;
    cursor: pointer;
    position: relative;
    width: 0;
}

.right-div {

  background-color: rgb(6, 6, 6);
  padding: 7px 8px; 
  position: absolute;
  top: 30px;
  right: 135px;
  border-radius: 8px;
  font-size: 12px;
}

.right-div div {
    margin-bottom: 8px; 
}

.line {
  border-top: 1px solid rgb(154, 154, 159); 
  margin-top: 10px; 
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  width:288;
}

.to-map {
  color: #39F056;
  text-decoration: underline; 
  display: flex; 
  align-items: center; 
  width:'19px';
  margin-left: 10px;
}

.link-icon {
  margin-left: 13px;
  Width:15.83px;
  height: 19px;
 } 

.details {
  color: #E7E7E7;
  font-size: 9px; 
  margin-left: 80px; 
  margin-bottom:7px;
  font-family: 'Inter';
  position:absolute;
}

.down-arrow {
  position: absolute;
  top: 98px;
  right: -13px;
}
