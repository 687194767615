.paginationDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.pagination {
    display: flex;
}

.page-number {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #c6e7fa;
    background-color: #f8f8f8;
    cursor: pointer;
}

.page-number.active,
.page-number:hover {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
